import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/Layout/Layout"
import Content, { HTMLContent } from "../components/Content"
import Heading from "../components/Heading/Heading"
import Section from "../components/Section/Section"
import Segment from "../components/Segment/Segment"
import useSiteMetadata from "../components/SiteMetadata"


export const GenericPageTemplate = ({
  content,
  contentComponent,
  title,
  subtitle,
  helmet
}) => {
  const PageContent = contentComponent || Content

  return (
    <Section>
      {helmet || ""}
      <Segment>
        <Heading level={1} modifiers={["major", "centred"]}>{title}</Heading>
        <Heading level={0} modifiers={["minor", "centred"]}>{subtitle}</Heading>
        <PageContent className="cms-content cms-content--generic" content={content} />
      </Segment>
    </Section>
  )
}

GenericPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  helmet: PropTypes.object,
}

const GenericPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { siteUrl } = useSiteMetadata()

  let ogImage = null

  if (post.frontmatter.ogImage){
    ogImage = post.frontmatter.ogImage.childImageSharp.fixed
  }

  return (
    <Layout>
      <GenericPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        helmet={
          <Helmet titleTemplate="%s | Trusty Canary">
            <title>{post.frontmatter.title}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:description"
              content={`${post.frontmatter.description}`}
            />
            {ogImage ? <meta property="og:image" content={`${siteUrl}${ogImage.src}`}/> : ""}
            {ogImage ? <meta property="og:image:height" content={ogImage.height}/> : ""}
            {ogImage ? <meta property="og:image:width" content={ogImage.width}/> : ""}
            <meta property="og:url" content={`${siteUrl}/${post.fields.slug}`}/>
          </Helmet>
        }
      />
    </Layout>
  )
}

GenericPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GenericPage

export const GenericPageQuery = graphql`
  query GenericPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        description
        ogImage: image {
          childImageSharp {
            fixed(
              width: 1200,
              quality: 100
            ) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`
